import Flex from "Components/Flex/Flex";
import React, { useEffect } from "react";
import styles from "../FindTrainerPopup.module.scss";
import RadioText from "./RadioText";
import Button from "Components/Button/Button";
import { ServicesMidle } from "Pages/PersonalCoach/filterData";
import toast from "react-hot-toast";
import customAxios from "Utils/axios/axios";
import CheckText from "./CheckText";

function YourGoalNutri({
  handle_next = () => {},
  handle_prev = () => {},
  theme = "main",
}) {
  const [selecteds, setselected] = React.useState([]);
  const [services, setServices] = React.useState([]);

  useEffect(() => {
    customAxios.get("/api/approche-nutritionnelle/get-all").then((resp) => {
      setServices(resp.data.data);
    });
  }, []);

  const handleSelect = (value) => {
    if (selecteds.includes(value)) {
      setselected(selecteds.filter((item) => item !== value));
    } else {
      setselected([...selecteds, value]);
    }
  };

  const SubmitAndNext = () => {
    if (selecteds.length === 0) {
      toast.error("Please select at least one service");
      return;
    }

    localStorage.setItem("service-nutri", JSON.stringify(selecteds));
    handle_next();
  };

  useEffect(() => {
    let selected = JSON.parse(localStorage.getItem("service-nutri") || "[]");
    if (selected.length > 0) {
      setselected(selected);
    }
  }, []);

  return (
    <Flex flex="between" className={`${styles.main} no-scroll-bar`}>
      <div className={styles.header}>
        <h1>What is your nutritional goals ?</h1>
      </div>

      <Flex className={`${styles.body} no-scroll-bar`}>
        <div className="w-full max-h-[300px] md:max-h-[600px] overflow-y-auto no-scroll-bar space-y-2">
          {ServicesMidle(services).map((item, index) => {
            return (
              <CheckText
                color={theme}
                key={index}
                text={item.label}
                checked={selecteds.includes(item.value)}
                handle_check={() => {
                  handleSelect(item.value);
                }}
              />
            );
          })}
        </div>
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_prev} color="black">
          Previus
        </Button>
        <Button onClick={SubmitAndNext} color={theme}>
          Next
        </Button>
      </Flex>
    </Flex>
  );
}

export default YourGoalNutri;
