import React from "react";
import styles from "./FilterBtn.module.scss";
import check_red from "../../../Assets/Svgs/general_icons/check_red.svg";
import useOutsideEvent from "../../../Hooks/UseOutside";
import { IoCloseSharp } from "react-icons/io5";

const FilterBtn = ({
  itemName = "",
  items = [],
  setFilter = () => {},
  filter = [],
}) => {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef(null);

  useOutsideEvent(ref, () => {
    setOpen(false);
  });

  const handleSelect = (item) => {
    if (filter.includes(item.value)) {
      setFilter(filter.filter((i) => i !== item.value));
    } else {
      setFilter([...filter, item.value]);
    }
  };

  const isSelected = (item) => {
    return filter.includes(item.value);
  };

  const handleSelectAll = () => {
    setFilter([]);
  };

  const isAllSelected = () => {
    return filter.length === 0;
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.filter_btn} ref={ref}>
      <span onClick={handleOpen} className={styles.actualButton}>
        {itemName}
      </span>
      {open && (
        <div className={styles.dropdown}>
          <div className={styles.items}>
            <Closer onClick={handleOpen} />
            <ItemSelect
              item={{
                label: "All",
                value: "",
              }}
              handleSelect={handleSelectAll}
              isSelected={isAllSelected}
            />
            {items.map((item, index) => {
              return (
                <ItemSelect
                  key={index}
                  item={item}
                  handleSelect={handleSelect}
                  isSelected={isSelected}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const ItemSelect = ({
  item = { label: "", value: "" },
  handleSelect = () => {},
  isSelected = () => {},
}) => {
  const { label } = item;

  const clickSelect = () => {
    handleSelect(item);
  };

  const check = isSelected(item);

  return (
    <div
      onClick={clickSelect}
      className={`${styles.item_select} ${check ? styles.checked : ""}`}
    >
      <div className={styles.item_select_box}>
        {check && <img src={check_red} alt="" />}
      </div>
      <div className={styles.item_select_text}>{label}</div>
    </div>
  );
};

const Closer = ({ onClick = () => {} }) => {
  return (
    <div className={styles.closeContainer} onClick={onClick}>
      <IoCloseSharp />
    </div>
  );
};

export default FilterBtn;
