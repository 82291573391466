import { toast } from "react-hot-toast";
import axios from "../Utils/axios/axios";

// ################################# KEYS #################################

const keys = {
  update_user: "update_user",
  payload: "payloade_user",
  connect: "connecte_user",
  all: "all_user",
};

// ################################# INIT #################################

export const InitialState = {
  payload: true,
  is_connected: false,
  user: {
    type: "",
    _id: "",
    fullname: "",
    email: "",
    phoneNumber: "",
    MainImg: "",
    createdAt: "",
    updatedAt: "",
  },
};

// ################################# REDUCERS #################################

export const UserReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.update_user:
      return { ...state, user: action.value };
    case keys.payload:
      return { ...state, payload: action.value };
    case keys.connect:
      return { ...state, connect: action.value };
    default:
      return state;
  }
};

// ################################# ACTIONS #################################

const ActionRegister = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.post(`/api/user/register`, {
        ...user,
      });

      // toast.success(`Welcome ${response?.data?.data?.fullname}`);
      dispatch(
        ActionLogin({ email: user.email, password: user.password }, callback)
      );
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.error(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const ActionLogin = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.post(`/api/auth/login`, {
        ...user,
      });
      const real_user = response?.data?.data;
      const { remember } = user;

      toast.success("Welcome to TRENA");

      if (remember) {
        localStorage.setItem("accessToken", response?.data?.token);
        localStorage.setItem("refreshToken", response?.data?.reftoken);
      } else {
        sessionStorage.setItem("accessToken", response?.data?.token);
        sessionStorage.setItem("refreshToken", response?.data?.reftoken);
      }

      // every thing is ok here
      dispatch({
        type: keys.all,
        value: {
          payload: false,
          is_connected: true,
          user: real_user,
        },
      });
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.error(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const ActionLoginFB = (accessToken, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.post(`/api/auth/login-facebook`, {
        accessToken,
      });
      const real_user = response?.data?.data;

      // to set the token and reftoken in storage
      // toast.success(`Good Morning ${real_user.fullname}`);
      localStorage.setItem("accessToken", response?.data?.token);
      localStorage.setItem("refreshToken", response?.data?.reftoken);
      // every thing is ok here
      dispatch({
        type: keys.all,
        value: {
          payload: false,
          is_connected: true,
          user: real_user,
        },
      });
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.error(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const ActionLoginGO = (accessToken, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.post(`/api/auth/login-google`, {
        accessToken,
      });
      const real_user = response?.data?.data;

      // to set the token and reftoken in storage
      // toast.success(`Good Morning ${real_user.fullname}`);
      localStorage.setItem("accessToken", response?.data?.token);
      localStorage.setItem("refreshToken", response?.data?.reftoken);
      // every thing is ok here
      dispatch({
        type: keys.all,
        value: {
          payload: false,
          is_connected: true,
          user: real_user,
        },
      });
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.error(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const GetUserByToken = (callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.get(`/api/auth/getbytoken`);

      // here check if it's admin or user

      dispatch({
        type: keys.all,
        value: {
          payload: false,
          is_connected: true,
          user: response.data.user,
        },
      });
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      callback();
      console.error(error?.response);
      // toast.error(error?.response?.data?.message);
    }
  };
};

const Disconnect = () => {
  return (dispatch) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    dispatch({
      type: keys.all,
      value: {
        ...InitialState,
      },
    });
  };
};

export {
  ActionRegister,
  ActionLogin,
  GetUserByToken,
  ActionLoginFB,
  ActionLoginGO,
  Disconnect,
};
