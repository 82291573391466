import React from "react";
import styles from "./ContactUs.module.scss";

import icon_user from "../../Assets/Svgs/contact/user.svg";
import icon_email from "../../Assets/Svgs/contact/email.svg";
import icon_subj from "../../Assets/Svgs/contact/subj.svg";
import icon_text from "../../Assets/Svgs/contact/text.svg";
import useForm from "../../Hooks/UseForm";

import mail_icon from "../../Assets/Svgs/contact/infos/email.svg";
import phone_icon from "../../Assets/Svgs/contact/infos/phone.svg";
import location_icon from "../../Assets/Svgs/contact/infos/location.svg";

import girl_img from "../../Assets/Images/contact.png";
import arrow_line from "../../Assets/Svgs/contact/arrow.svg";

import Button from "../../Components/Button/Button";
import { GapNavbarSpacer } from "../../Layouts/Navbar/Navbar";
import customAxios from "Utils/axios/axios";
import toast from "react-hot-toast";

function ContactUs() {
  const [form, onChange, reset] = useForm({
    fullname: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleSubmit = () => {
    customAxios
      .post("/api/contact/create", {
        name: form.fullname,
        email: form.email,
        subject: form.subject,
        message: form.message,
      })
      .then((res) => {
        toast.success("Message sent successfully");
        reset();
      });
  };

  return (
    <div>
      <GapNavbarSpacer />
      <div className={styles.main}>
        <div className={styles.left}>
          <h1>
            Get <br /> In Touch
          </h1>

          <Input
            icon={icon_user}
            placeholder="Full Name"
            name="fullname"
            value={form.fullname}
            onChange={onChange}
          />
          <Input
            icon={icon_email}
            name="email"
            value={form.email}
            placeholder="Email"
            onChange={onChange}
          />
          <Input
            icon={icon_subj}
            placeholder="Subject"
            name="subject"
            value={form.subject}
            onChange={onChange}
          />
          <TextArea
            icon={icon_text}
            // placeholder="How can we help you? Do not hesitate to contact us !"
            placeholder="How can we help you?"
            name="message"
            rows={4}
            value={form.message}
            onChange={onChange}
          />
          <br />
          <Button onClick={handleSubmit} className={styles.btn}>
            Send
          </Button>
        </div>
        <div className={styles.right}>
          <LinksCard />
          <div className={styles.girl_img}>
            <img src={girl_img} alt="" />
          </div>
          <img src={arrow_line} alt="" className={styles.arrow_line} />
        </div>
      </div>
    </div>
  );
}

const Input = ({
  icon = "",
  placeholder = "",
  name = "",
  value = "",
  onChange = () => {},
}) => {
  return (
    <div className={styles.input}>
      <img src={icon} alt="" />
      <input
        type="text"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

const TextArea = ({
  icon = "",
  placeholder = "",
  name = "",
  value = "",
  onChange = () => {},
  rows = 5,
}) => {
  return (
    <div className={styles.input}>
      <img src={icon} alt="" />
      <textarea
        type="text"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        rows={rows}
      />
    </div>
  );
};

const LinksCard = () => {
  return (
    <div className={styles.links_card}>
      <div className={styles.link_info}>
        <img src={mail_icon} alt="" />
        <span>
          <a href="mailto:hello@trena.pro">hello@trena.pro</a>
        </span>
      </div>
      <div className={styles.link_info}>
        <img src={phone_icon} alt="" />
        <a href="tel:+21654007008">
          <span>+216 54007008</span>
        </a>
      </div>
      {/* <div className={styles.link_info}>
        <img src={location_icon} alt="" />
        <span>Tunis,1265N°2 tunis ,Tunisie</span>
      </div> */}
    </div>
  );
};

export default ContactUs;
