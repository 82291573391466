import Container from "Components/Container/Container";
import React, { useState } from "react";
import { plans } from "./data";
import { parseTextWithBold } from "Utils/TextBolder/TextBolder";
import useMustRegister from "Common/useMustLogin";
import { useBounceEffect } from "Hooks/useBounceEffect";

function PricingPlans({ showType = "month" }) {
  const { MustRegister, onClick_withExistUser } = useMustRegister();
  const type = showType === "monthly" ? "month" : "year";

  const OpenPlan = () => {
    onClick_withExistUser(() => {
      console.error("Open Plan");
    });
  };

  return (
    <Container>
      <MustRegister />
      <div className="flex-col sm:flex-row flex-wrap w-full flex items-start justify-between">
        <FirstPlan type={type} onClick={OpenPlan} />
        <SecondPlan type={type} onClick={OpenPlan} />
        <ThirdPlan type={type} onClick={OpenPlan} />
      </div>
    </Container>
  );
}

// ##############################################################################
// ################################### PLANS ####################################
// ##############################################################################

const FirstPlan = ({ type, onClick = () => {} }) => {
  return (
    <PlanContainer
      className="border-2 hover:border-black"
      theme="white"
      plan={plans.first}
      type={type}
      popular={true}
      onClick={onClick}
    />
  );
};

const SecondPlan = ({ type, onClick = () => {} }) => {
  return (
    <PlanContainer
      className="border-2 hover:border-black"
      theme="between"
      plan={plans.secon}
      type={type}
      popular={false}
      onClick={onClick}
    />
  );
};

const ThirdPlan = ({ type, onClick = () => {} }) => {
  return (
    <PlanContainer
      className="!bg-[#050505] border-2 hover:border-black"
      theme="black"
      plan={plans.third}
      type={type}
      popular={false}
      onClick={onClick}
    />
  );
};

// ##############################################################################
// ################################### PLANS ####################################
// ##############################################################################

// ##############################################################################
// ############################### ITEMS IN PLANS ###############################
// ##############################################################################

const PlanContainer = ({
  className = "",
  plan = {},
  theme = "white",
  type = "month",
  popular = false,
  onClick = () => {},
}) => {
  const { title, icon, description, prices, features, comingSoon } = plan;

  const themes = {
    white: {
      title: "text-black",
      button: "bg-black text-white",
      actualPrice: "opacity-0",
      popular: "",
      features:
        "bg-gradient-to-b from-[#eeeeee] to-[#F0F0F0] border border-[#dddddd]",
      featuresItems: "text-black",
      featuresImage: "/svgs/check.svg",
      featuresMoreIcon: "/svgs/chevron_black.svg",
      featuresBorder: "border-[#aaaaaa]",
    },
    between: {
      title: "text-black",
      button: "text-white bg-mainColor",
      actualPrice: "",
      popular: "",
      features:
        "bg-gradient-to-b from-[#eeeeee] to-[#F0F0F0] border border-[#BEBEBE]",
      featuresItems: "text-black",
      featuresImage: "/svgs/check2.svg",
      featuresMoreIcon: "/svgs/chevron_black.svg",
      featuresBorder: "border-[#aaaaaa]",
    },
    black: {
      title: "text-white",
      button: "bg-[#DBAB1C] text-white",
      actualPrice: "",
      popular: "",
      features: "bg-[#171616] border border-[#555555]",
      featuresText: "text-white",
      featuresImage: "/svgs/check3.svg",
      featuresMoreIcon: "/svgs/chevron_white.svg",
      featuresBorder: "border-[#dddddd]",
    },
    // black: {
    //   main: "border-2 hover:border-black hover:!bg-[#050505]",
    //   title: "text-black group-hover:text-white",
    //   button:
    //     "bg-black text-white group-hover:!bg-[#DBAB1C] group-hover:!text-black",
    //   actualPrice: "",
    //   popular: "",
    //   features:
    //     "bg-gradient-to-b from-[#eeeeee] to-[#F0F0F0] border border-[#BEBEBE] group-hover:!from-[#171616] group-hover:!to-[#171616] group-hover:!border-[#555555]",
    //   featuresItems: "text-white group-hover:!text-white",
    //   featuresText: "text-black group-hover:!text-white",
    //   featuresImage: "/svgs/check2.svg",
    //   featuresMoreIcon: "/svgs/chevron_black.svg",
    //   featuresBorder: "border-[#aaaaaa] group-hover:!border-[#555555]",
    //   featuresImageStyle: "backdrop-filter group-hover:invert",
    //   featuresMoreIconStyle: "backdrop-filter group-hover:invert",
    // },
  };

  const ct = themes[theme];

  return (
    <div
      className={`w-[100%] sm:w-[48%] lg:w-[31.5%] min-h-[55vw] rounded-[2.5vw] pt-[6vw] sm:pt-[3vw] lg:pt-[2.3vw] pb-[4vw] sm:pb-[2vw] lg:pb-[1.2vw] px-[3vw] sm:px-[2vw] lg:px-[1.2vw] flex flex-col bg-[#F6F6F7] mb-[7vw] lg:mb-[5vw] cursor-default hover:shadow-xl hover:scale-[1.02] group transition-all duration-200 select-none relative ${className}`}
    >
      {popular && <Popupar ct={ct} />}
      <Header title={title} icon={icon} description={description} ct={ct} />
      <Price price={prices} type={type} ct={ct} />
      <ButtonStarted ct={ct} onClick={onClick} comingSoon={comingSoon} />
      <Features ct={ct} features={features} />
    </div>
  );
};

// ##############################################################################
// ############################### ITEMS IN PLANS ###############################
// ##############################################################################

const Popupar = ({ ct = {} }) => {
  return (
    <div
      className={`-top-[2vw] sm:-top-[2vw] lg:-top-[1vw] right-[4vw] sm:right-[3vw] lg:right-[1vw] px-[1.5vw] py-[1vw] sm:py-[0.5vw] lg:py-[0.3vw] sm:pl-[0.7vw] lg:pl-[0.5vw] sm:pr-[0.9vw] lg:pr-[0.7vw] rounded-full flex items-center justify-center absolute border border-black bg-[#F6F6F7] animate-scaleunscale ${ct.popular}`}
    >
      <img
        src="/svgs/icons/fire.png"
        className="h-[4vw] sm:h-[2.2vw] lg:h-[1.6vw] mr-[0.2vw]"
        alt=""
      />
      <span className="text-[2.5vw] sm:text-[1.7vw] lg:text-[1.2vw] font-bold translate-y-[0.1vw] ">
        Most popular
      </span>
    </div>
  );
};

const Header = ({
  title = "",
  description = "",
  icon = "/svgs/icons/bolt.png",
  ct = {},
}) => {
  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-center">
        <img
          src={icon}
          className="w-[7vw] sm:w-[3vw] lg:w-[1.6vw] h-[7vw] sm:h-[3vw] lg:h-[1.6vw] mr-[0.6vw] lg:mr-[0.3vw] mb-[0.5vw] lg:mb-[0.2vw]"
          alt={title}
        />
        <h1
          className={`text-center text-[6vw] sm:text-[2.5vw] lg:text-[1.7vw] font-semibold sm:font-medium ${ct?.title}`}
        >
          {title}
        </h1>
      </div>
      <p
        className={`text-center text-[3vw] sm:text-[1.5vw] lg:text-[1.05vw] w-[100%] sm:w-[95%] lg:w-[80%] mx-auto mt-[0.5vw] font-medium leading-[1.5] ${ct?.title}`}
      >
        {description}
      </p>
    </div>
  );
};

const OldPrice = ({ price = {}, type = "month", ct = {} }) => {
  return (
    <div className={`w-full`}>
      <h2
        className={`text-center text-mainColor text-[3.5vw] sm:text-[2vw] lg:text-[1vw] flex items-center justify-center select-none ${ct.actualPrice}`}
      >
        <span className="text-[4vw] sm:text-[2.5vw] lg:text-[1.2vw] line-through">
          {price["actualPrice"]}TND
        </span>
        <span className="block mt-[0.3vw]">/{type}</span>
      </h2>
    </div>
  );
};

const Price = ({ price = {}, type = "month", ct = {} }) => {
  return (
    <div className="w-full mt-[5vw] sm:mt-[1.5vw]">
      {type === "year" && <OldPrice price={price} type={type} ct={ct} />}
      <h2
        className={`text-center text-[6vw] sm:text-[3vw] lg:text-[1.5vw] font-medium flex items-center justify-center ${ct?.title}`}
      >
        <span className="text-[8vw] sm:text-[4vw] lg:text-[2.9vw] font-bold leading-[1.4]">
          {price[type]}TND
        </span>
        <span className="block mt-[0.6vw]">/{type}</span>
      </h2>
    </div>
  );
};

export const ButtonStarted = ({ ct = {}, onClick = () => {}, comingSoon }) => {
  const st = useBounceEffect({
    interval: 5000 + Math.random() * 5000,
    scale: 1.05,
    duration: 300,
  });

  return (
    <div className="w-full mt-[1.6vw]">
      <button
        style={st}
        disabled={comingSoon}
        onClick={onClick}
        className={`text-[3.5vw] sm:text-[1.8vw] lg:text-[1.15vw] font-medium w-[70%] sm:w-[83%] py-[2vw] sm:py-[1.2vw] lg:py-[0.9vw] rounded-full mx-auto block transition-all duration-200 hover:scale-105 
          ${comingSoon ? "cursor-not-allowed opacity-80" : "cursor-pointer"}
          ${ct?.button}`}
      >
        {comingSoon ? "Coming Soon" : "Get Started"}
      </button>
    </div>
  );
};

const Features = ({ features = { main: [], more: [] }, ct = {} }) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div
      className={`w-full mt-[4vw] sm:mt-[3vw] lg:mt-[2vw] rounded-[2vw] sm:rounded-[1.5vw] lg:rounded-[0.7vw] p-[3vw] sm:p-[2vw] grow flex flex-col items-start justify-between ${ct?.features}`}
    >
      <div>
        {features.main.map((feature, index) => (
          <Feature key={index} ct={ct}>
            {feature}
          </Feature>
        ))}
      </div>

      {showMore && (
        <div className="mt-[0.5vw]">
          {features.more.map((feature, index) => (
            <Feature key={index} ct={ct}>
              {feature}
            </Feature>
          ))}
        </div>
      )}

      {features.more.length > 0 && (
        <div
          onClick={handleShowMore}
          className={`text-[4vw] sm:text-[2vw] lg:text-[1.2vw] pt-[1.5vw] mt-[1vw] border-t-2 w-full text-center font-medium flex items-center justify-center cursor-pointer ${ct.featuresText} ${ct.featuresBorder}`}
        >
          {!showMore && <span>See all features</span>}
          {showMore && <span>Show Less</span>}
          <img
            className={`h-[5vw] sm:h-[3vw] lg:h-[1.8vw] ${
              showMore ? "rotate-180" : "rotate-0"
            }`}
            src={ct.featuresMoreIcon}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

const Feature = ({ children = "", ct = {} }) => {
  return (
    <div className="flex items-start justify-start mb-[4vw] sm:mb-[2vw] lg:mb-[1vw]">
      <img
        className="mr-[1.5vw] sm:mr-[0.5vw] w-[5vw] sm:w-[2.5vw] lg:w-[1.5vw] min-w-[5vw] sm:min-w-[2.5vw] lg:min-w-[1.5vw] translate-y-[0.1vw]"
        src={ct.featuresImage}
        alt="feature"
      />
      <p
        className={`text-[3vw] sm:text-[1.5vw] lg:text-[0.9vw] font-medium ${ct.featuresText}`}
      >
        {parseTextWithBold(children)}
      </p>
    </div>
  );
};

export default PricingPlans;
