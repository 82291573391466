import React from "react";
import ImgBack from "../../../Components/Image/ImgBack";
import { useSelector } from "react-redux";
import { useUser } from "Store/hooks/useUser";
import UploadProfileImage from "Components/Input/UploadProfileImage/UploadProfileImage";

const TopHeader = () => {
  const { user } = useUser();

  let userType = {
    COACH: "Personal Coach",
    USER: "Client",
    NUTRITIONIST: "Nutritionist",
  };

  return (
    <div className="w-full h-[10vw] flex items-center justify-center relative ">
      <ImgBack
        src={user.CoverProfileImage}
        altsrc="/images/cover.jpg"
        alt="settings"
        className="w-full h-full object-cover rounded-t-[2vw]"
      />
      <UploadProfileImage
        imgType="CoverProfileImage"
        className="absolute top-[1vw] right-[1vw] w-[2.5vw] h-[2.5vw] cursor-pointer transition-all duration-200 hover:scale-110"
      />
      <div className="h-[120%] w-[87%] absolute top-[55%] flex items-end justify-start">
        <div className="h-full aspect-square rounded-full border-[0.4vw] relative border-white">
          <ImgBack
            src={user.MainImg}
            alt="settings"
            className="w-full h-full object-cover bg-gray-200 rounded-full"
          />
          <UploadProfileImage
            imgType="MainImg"
            className="absolute bottom-[0.3vw] right-[0.3vw] w-[2.7vw] h-[2.7vw] cursor-pointer transition-all duration-200 hover:scale-110"
          />
        </div>
        <div className="ml-[1vw] w-1/2 h-[55%]">
          {/* <h1></h1> */}
          <h1 className="text-[1.8vw] font-semibold leading-1">
            {user?.fullname}
          </h1>
          <h3 className="text-[1.3vw] -mt-[0.5vw] text-textGray">
            {userType[user?.type]}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
