import { useEffect, useRef, useState } from "react";

function useBounceEffect({ interval = 3000, scale = 1.2, duration = 400 }) {
  const [isBouncing, setIsBouncing] = useState(false);

  useEffect(() => {
    const bounce = () => {
      setIsBouncing(true);
      setTimeout(() => setIsBouncing(false), duration); // Reset after animation
    };

    const intervalId = setInterval(bounce, interval);
    return () => clearInterval(intervalId);
  }, [interval, duration]);

  return isBouncing
    ? {
        transform: `scale(${scale})`,
        transition: `transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1)`,
      }
    : {
        transform: "scale(1)",
        transition: `transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1)`,
      };
}

export { useBounceEffect };
