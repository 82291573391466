import React, { useEffect, useState } from "react";

import Dialogue from "../../../Components/Dialogue/Dialogue";

import AuthPart from "./components/AuthPart";
import { useUser } from "Store/hooks/useUser";
import Location from "./components/Location";
import YourGoalCoach from "./components/YourGoalCoach";
import WorkoutDaysOfWeek from "./components/WorkoutDaysOfWeek";
import CoachingStylePart from "./components/CoachingStyle";
import ChoosingGender from "./components/ChoosingGender";
import FinaliseCoachFilter from "./components/FinaliseCoachFilter";

function FindTrainerPopup({ open = false, handle_close }) {
  const [step, setstep] = useState(0);
  const { is_connected } = useUser();

  const Steps = [
    // AuthPart,
    Location,
    YourGoalCoach,
    // WorkoutDaysOfWeek,
    // CoachingStylePart,
    ChoosingGender,
    // FinaliseCoachFilter,
  ];

  const handle_next = () => {
    if (step < Steps.length - 1) {
      setstep(step + 1);
    }
  };

  const handle_prev = () => {
    if (step > 0) {
      setstep(step - 1);
    }
  };

  // useEffect(() => {
  //   if (is_connected) {
  //     setstep(1);
  //   }
  // }, [is_connected]);

  let Step = Steps[step];

  return (
    <Dialogue handleClose={handle_close} open={open}>
      {
        <Step
          handle_next={handle_next}
          handle_prev={handle_prev}
          handle_close={handle_close}
          theme="main"
        />
      }
    </Dialogue>
  );
}

export default FindTrainerPopup;
