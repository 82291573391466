import React from "react";
import Dialogue from "../../../Components/Dialogue/Dialogue";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

function CoachPopup({ open = false, handle_close = () => {}, oneCoash }) {
  return (
    <Dialogue handleClose={handle_close} open={open}>
      <div className="w-[100%] md:w-[53vw] px-[5vw] md:px-[4.5vw] py-[4vw] md:py-[3vw] bg-white">
        <TopPart oneCoash={oneCoash} />
      </div>
    </Dialogue>
  );
}

const TopPart = ({ oneCoash }) => {
  return (
    <div className="w-full h-full">
      <h2 className="text-center text-[5vw] md:text-[2.3vw] font-semibold">
        Contact {oneCoash?.fullname}
      </h2>

      <p className="text-gray-500 text-[2.8vw] md:text-[1.3vw] mb-[4vw] md:mb-[2.6vw] text-center">
        Need to talk to {oneCoash?.fullname}? Click{" "}
        <span className="text-mainColor underline cursor-pointer font-semibold">
          HERE
        </span>{" "}
        to join a private chat now!
      </p>

      <RoundedBox text="Phone number :">
        <p className="text-[3.3vw] md:text-[1.3vw] py-[2vw] md:py-[1vw]">
          {oneCoash?.phoneNumber}
        </p>
      </RoundedBox>

      <RoundedBox text="Email :">
        <p className="text-[3.3vw] md:text-[1.3vw] py-[2vw] md:py-[1vw]">
          {oneCoash?.email}
        </p>
      </RoundedBox>

      <RoundedBox text="Social media :">
        <SocialLinks oneCoash={oneCoash} />
      </RoundedBox>

      <RoundedBox text="Portfolio Link :">
        <Clipboard />
      </RoundedBox>
    </div>
  );
};

const RoundedBox = ({ text = "", children }) => {
  return (
    <div className="w-full px-[3vw] md:px-[1vw] mb-[3vw] md:mb-[1vw] flex items-center justify-start rounded-[1.2vw] md:rounded-[0.7vw] border border-border">
      <p className="text-[3.3vw] md:text-[1.3vw] mr-[0.5vw] font-semibold min-w-fit">
        {text}
      </p>{" "}
      {children}
    </div>
  );
};

const Clipboard = () => {
  const location = useLocation();

  const copyToClipboard = () => {
    // let url = `${process.env.REACT_APP_API_HOST}/#${location.pathname}`;
    let url = `${process.env.REACT_APP_API_HOST}/#${location.pathname}`;
    navigator.clipboard.writeText(url);
    toast.success("Link copied to clipboard");
  };

  return (
    <p className="text-[3.3vw] md:text-[1.3vw] py-[2vw] md:py-[1vw] overflow-x-hidden h-full text-nowrap  flex items-center justify-start relative">
      <span className="overflow-x-hidden text-ellipsis w-[72%] md:w-[73%]">
        {process.env.REACT_APP_API_HOST + location.pathname}
      </span>
      <div
        onClick={copyToClipboard}
        className="cursor-pointer text-[3vw] md:text-[1.2vw] px-[3vw] md:px-[2vw] py-[1vw] md:py-[0.6vw] rounded-[0.5vw] right-0 font-semibold absolute  bg-[#F5F7FA]"
      >
        COPY
      </div>
    </p>
  );
};

const SocialLinks = ({ oneCoash }) => {
  if (!oneCoash) return null;

  const {
    Instagram,
    Youtube,
    Facebook,
    Twitter,
    Linkedin,
    SnapChat,
    TikTok,
    PortfolioUrl,
  } = oneCoash;

  const haveNothing =
    !Instagram &&
    !Youtube &&
    !Facebook &&
    !Twitter &&
    !Linkedin &&
    !SnapChat &&
    !TikTok &&
    !PortfolioUrl;

  return (
    <div className="space-x-[1.2vw] ml-[1vw] md:ml-[0vw] md:space-x-[1vw] py-[2vw] md:py-[1vw]  flex items-center justify-start ">
      {/* {Facebook && <RX img={"/svgs/rxs/fb.svg"} link={Facebook} />}
      {Twitter && <RX img={"/svgs/rxs/tw.svg"} link={Twitter} />}
      {TikTok && <RX img={"/svgs/rxs/tk.svg"} link={TikTok} />}
      {Linkedin && <RX img={"/svgs/rxs/in.svg"} link={Linkedin} />}
      {Youtube && <RX img={"/svgs/rxs/yt.svg"} link={Youtube} />}
      {SnapChat && <RX img={"/svgs/rxs/sn.svg"} link={SnapChat} />}
      {Instagram && <RX img={"/svgs/rxs/st.svg"} link={Instagram} />}
      {PortfolioUrl && <RX img={"/svgs/rxs/ww.svg"} link={PortfolioUrl} />} */}
      <RX img={"/svgs/rxs/fb.svg"} link={Facebook} />
      <RX img={"/svgs/rxs/tw.svg"} link={Twitter} />
      <RX img={"/svgs/rxs/tk.svg"} link={TikTok} />
      <RX img={"/svgs/rxs/in.svg"} link={Linkedin} />
      <RX img={"/svgs/rxs/yt.svg"} link={Youtube} />
      <RX img={"/svgs/rxs/sn.svg"} link={SnapChat} />
      <RX img={"/svgs/rxs/st.svg"} link={Instagram} />
      <RX img={"/svgs/rxs/ww.svg"} link={PortfolioUrl} />

      {haveNothing && (
        <p className="text-[3.3vw] md:text-[1.3vw]">No social media</p>
      )}
    </div>
  );
};

const RX = ({ img, link }) => {
  return (
    <a href={link || "/"} target="_blank" rel="noreferrer">
      <img
        src={img}
        alt=""
        className="w-[6vw] md:w-[2.5vw] aspect-square cursor-pointer"
      />
    </a>
  );
};

export default CoachPopup;

// 0nly4D0ttR!<3
