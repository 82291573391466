import React, { useEffect, useState } from "react";
import styles from "./Profile.module.scss";
import { GapNavbarSpacer } from "../../Layouts/Navbar/Navbar";
import cover from "../../Assets/Images/Tempo/one_coach/cover.jpg";
import GoBack from "../../Components/GoBack/GoBack";
import { useDispatch, useSelector } from "react-redux";
import CardCoach from "../../Components/Cards/CardCoach/CardCoach";
import { Blog } from "../TrenaPedia/TrenaPedia";
import Flex from "../../Components/Flex/Flex";
import { ImExit } from "react-icons/im";
import { Disconnect } from "../../Store/user.reducer";
import { useNavigate } from "react-router-dom";
import customAxios from "../../Utils/axios/axios";

// same as the coach page

const MyFavoritCoaches = () => {
  const [coaches, setCoashes] = useState([]);

  useEffect(() => {
    customAxios
      .get("/api/coache/get-all")
      .then((resp) => {
        setCoashes(resp.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className={styles.trainers}>
      {coaches.map((item, index) => {
        return (
          <CardCoach
            className={styles.trainer}
            white_card
            data={item}
            key={index}
          />
        );
      })}
    </div>
  );
};

const MyFavoritBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    customAxios.get("/api/blogs/get-all").then((res) => {
      setBlogs(res.data.data);
    });
  }, []);

  return (
    <div className={styles.blogs}>
      {blogs.map((blog) => (
        <Blog key={blog.id} blog={blog} />
      ))}
    </div>
  );
};

const Sections = [
  // {
  //   title: "My Profile",
  //   component: <MyProfile />,
  // },
  {
    title: "Fav Coaches",
    component: <MyFavoritCoaches />,
  },
  {
    title: "Fav Blogs",
    component: <MyFavoritBlogs />,
  },
];

function Profile() {
  const [section, setSection] = useState(Sections[0]);

  return (
    <div className={styles.main}>
      <Header />
      <Switcher selected={section.title} setSection={setSection} />
      <Body Comp={section.component} />
    </div>
  );
}

const Header = () => {
  const { user } = useSelector((state) => state.UserReducer);

  return (
    <div>
      <GapNavbarSpacer />
      <div className={styles.header}>
        <img src={cover} className={styles.cover} alt="" />
        <div className={styles.filter} />
        <GoBack color="white" to="/" className={styles.content}>
          Back To Home
        </GoBack>
      </div>

      <div className={styles.header2}>
        <div className={styles.avatar}>
          <div className={styles.avatar_img}>
            <img src={user.MainImg} alt="" />
          </div>

          <div className={styles.name}>
            <h3>{user.fullname}</h3>
            <span>
              Welcome to <strong>TRENA</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Switcher = ({ selected = "", setSection }) => {
  const dispatch = useDispatch();
  const navig = useNavigate();

  const handleDisconnect = () => {
    navig("/");
    dispatch(Disconnect());
  };

  return (
    <div className={styles.switcher}>
      <Flex>
        {Sections.map((item, index) => {
          return (
            <div
              className={`
            ${styles.switcher_item} 
            ${selected === item.title ? styles.selected : ""}`}
              key={index}
              onClick={() => {
                setSection(item);
              }}
            >
              <span>{item.title}</span>
            </div>
          );
        })}
      </Flex>
      <div className={styles.switcher_item} onClick={handleDisconnect}>
        <ImExit className={styles.icon} />
        <span>Logout</span>
      </div>
    </div>
  );
};

const Body = ({ Comp }) => {
  return <div className={styles.body}>{Comp}</div>;
};

export default Profile;
