import { toast } from "react-hot-toast";
import axios from "../Utils/axios/axios";

// ################################# KEYS #################################

const keys = {
  update_coaches: "update_coaches",
  payload: "payloade_coache",
  all: "all_coache",
};

// ################################# INIT #################################

export const InitialState = {
  payload: false,
  coaches: [],
};

// ################################# REDUCERS #################################

export const CoacheReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.update_coaches:
      return { ...state, coache: action.value };
    case keys.payload:
      return { ...state, payload: action.value };
    default:
      return state;
  }
};

// ################################# ACTIONS #################################

const GetCoachesAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.get(`/api/coache/get-all`);

      // every thing is ok here
      dispatch({
        type: keys.all,
        value: {
          payload: false,
          coaches: response?.data?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.error(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

export { GetCoachesAction };
