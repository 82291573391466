import React from "react";
import { datas, items } from "./data";
import { useBounceEffect } from "Hooks/useBounceEffect";

//  ROWS ##############################

export const CompaireTable = () => {
  return (
    <div className="w-full overflow-x-auto pb-[30px] no-scroll-bar">
      <div className="w-[900px] lg:w-full border-[2px] lg:border-[3px] border-borderGray rounded-[4vw] sm:rounded-[2vw] relative">
        <HeaderRow />
        {items.map((item, key) => {
          return <RowItem key={key} item={item} />;
        })}
        {/* <BorderBlack /> */}
      </div>
    </div>
  );
};

export const HeaderCell = ({
  className = "",
  data = datas.free,
  type = "free",
}) => {
  const { title, icon, comingSoon } = data;

  const themes = {
    free: {
      button:
        // "bg-white text-black hover:bg-black hover:text-white border-2 border-border",
        "bg-black text-white",
    },
    pro: {
      button: "text-white bg-mainColor",
    },
    premium: {
      button: "bg-[#DBAB1C] text-white",
    },
  };

  return (
    <div className={`w-1/4 py-[2.5vw] ${className}`}>
      <HeaderText title={title} icon={icon} />
      {/* <p
        className={`text-center text-[20px] lg:text-[1.4vw] mx-auto font-medium`}
      >
        {price}
      </p> */}
      {/* <p className={`text-center text-[14px] lg:text-[1vw] mx-auto`}>
        {description}
      </p> */}
      <ButtonStarted ct={themes[type]} comingSoon={comingSoon} />
    </div>
  );
};

export const HeaderText = ({
  title = "",
  icon = "/svgs/icons/bolt.png",
  ct = {},
}) => {
  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-center">
        {/* <img
          src={icon}
          className="w-[30px] lg:w-[1.6vw] h-[30px] lg:h-[1.6vw] mr-[3px] lg:mr-[0.3vw] mb-[0.2vw]"
          alt={title}
        /> */}
        <h1
          className={`text-center text-[25px] lg:text-[1.7vw] font-bold ${ct?.title}`}
        >
          {title}
        </h1>
      </div>
    </div>
  );
};

export const ButtonStarted = ({ ct = {}, comingSoon = false }) => {
  const st = useBounceEffect({
    interval: 5000 + Math.random() * 5000,
    scale: 1.05,
    duration: 300,
  });

  return (
    <div className="w-full mt-[0.5vw]">
      <button
        style={st}
        className={`text-[14px] lg:text-[1vw] font-medium w-[75%] py-[5px] lg:py-[0.8vw] rounded-full mx-auto block transition-all duration-200 hover:scale-105 
          ${comingSoon ? "cursor-not-allowed opacity-80" : "cursor-pointer"}
          ${ct?.button}`}
      >
        {comingSoon ? "Coming Soon" : "Get Started"}
      </button>
    </div>
  );
};

export const HeaderRow = () => {
  return (
    <div className="w-full flex items-stretch justify-start">
      <div className="w-1/4" />
      <HeaderCell className="" data={datas.free} type="free" />
      <HeaderCell
        className="border-x-[2px] lg:border-x-[3px] border-borderGray"
        data={datas.pro}
        type="pro"
      />
      <HeaderCell className="" data={datas.premium} type="premium" />
    </div>
  );
};

const BorderBlack = () => {
  return (
    <div className="w-1/4 h-full top-0 right-0 outline outline-black rounded-l-none rounded-[2vw] absolute" />
  );
};

const RowItem = ({ item = {} }) => {
  const { text = "", free = 0, pro = 0, premium = 1 } = item;

  return (
    <div className="w-full border-t-[2px] lg:border-t-[3px] hover:bg-gray-50 border-borderGray flex items-stretch justify-start transition-all duration-150">
      <OneBoxText text={text} />
      <OneBoxCheck value={free} />
      <OneBoxCheck
        value={pro}
        className="border-x-[2px] lg:border-x-[3px] border-borderGray"
      />
      <OneBoxCheck value={premium} />
    </div>
  );
};

const OneBoxText = ({ text = "" }) => {
  return (
    <div className="w-1/4 py-[15px] lg:py-[2vw]">
      <p className="text-[16px] lg:text-[1.4vw] pl-[20px] lg:pl-[2.3vw]">
        {text}
      </p>
    </div>
  );
};

const OneBoxCheck = ({ value, className = "" }) => {
  let checked = value === 1 ? "green" : "red";
  return (
    <div className={`w-1/4 flex items-center justify-center ${className}`}>
      <img
        src={`/svgs/icons/check_${checked}.svg`}
        className="w-[28px] lg:w-[2.5vw]"
        alt=""
      />
    </div>
  );
};
