import React, { useEffect } from "react";

export function useFromFindCoachPopup({ setFilter = () => {} }) {
  useEffect(() => {
    let gender = localStorage.getItem("prefer-gender");
    let locations = JSON.parse(localStorage.getItem("locations") || "[]");
    let services = JSON.parse(localStorage.getItem("service-coach") || "[]");

    let coachingStyle = localStorage.getItem("coaching-style");
    let workoutDays = JSON.parse(localStorage.getItem("workout-days") || "[]");

    console.log("gender", gender);
    console.log("locations", locations);
    console.log("services", services);

    let filter = { services: [], locations: [], gender: [] };

    if (gender === "WOMEN" || gender === "MEN") {
      filter = { ...filter, gender };
    }

    if (locations.length > 0) {
      filter = { ...filter, locations: locations };
    }

    if (services.length > 0) {
      filter = { ...filter, services };
    }

    setFilter({ ...filter });

    localStorage.removeItem("prefer-gender");
    localStorage.removeItem("locations");
    localStorage.removeItem("service-coach");
    localStorage.removeItem("coaching-style");
    localStorage.removeItem("workout-days");
  }, []);

  return {};
}

export function useFromFindNutriPopup({ setFilter = () => {} }) {
  useEffect(() => {
    let gender = localStorage.getItem("prefer-gender");
    let locations = JSON.parse(localStorage.getItem("locations") || "[]");
    let services = JSON.parse(localStorage.getItem("service-nutri") || "[]");

    let coachingStyle = localStorage.getItem("coaching-style");
    console.log(gender, coachingStyle, locations, services);

    let filter = { services: [], locations: [], gender: [] };

    if (gender === "WOMEN" || gender === "MEN") {
      filter = { ...filter, gender };
    }

    if (locations.length > 0) {
      filter = { ...filter, locations: locations };
    }

    if (services.length > 0) {
      filter = { ...filter, services };
    }

    setFilter({ ...filter });

    localStorage.removeItem("prefer-gender");
    localStorage.removeItem("locations");
    localStorage.removeItem("service-nutri");
    localStorage.removeItem("coaching-style");
  }, []);

  return {};
}
